import { getCatalogPath } from '../../../libs/paths';
import { CatalogCategoryType } from '../types';
import certificationPrepIllustration from './assets/certificationPrepIllustration.svg';
import { SortBy } from './PaginatedCatalog/SortByDropdown/types';

export const catalogHomePageName = 'home_catalog';

export const aiSubject = {
  title: 'AI',
  slug: 'artificial-intelligence',
  type: 'Category' as const,
  categoryType: 'subject',
};

export const certificationPrepSpotlightContent = {
  eyebrow: 'New',
  header: 'Prepare for Top IT Certifications',
  description:
    'Take your IT career up a notch with new prep for top industry certifications. Get everything you need to prepare for certifications like CompTIA Security+, Cisco Certified Network Associate (CCNA), Certified Kubernetes Administrator (CKA), and more with expertly-designed paths and realistic exam simulations. Not ready to commit? Start with a free course to build a strong foundation.',
  slug: 'information-technology',
  cta: {
    text: "Explore what's new in IT",
    href:
      getCatalogPath({
        categoryType: CatalogCategoryType.Subject,
        category: 'information-technology',
      }) + '?type=skill-path',
  },
  imageSrc: certificationPrepIllustration,
};

export const CATALOG_PAGE_SIZE = 48;

export const DEFAULT_SORT_BY = SortBy.POPULAR;

export const featuredContainerSlugs = [
  'introduction-to-cybersecurity',
  'full-stack-engineer-career-path',
  'code-foundations',
  'learn-html',
  'computer-science',
  'learn-how-to-build-websites',
  'intro-to-chatgpt',
  'bi-data-analyst',
  'analyze-data-with-sql',
  'introduction-to-javascript',
  'front-end-engineer-career-path',
  'machine-learning-ai-engineering-foundations',
];

export const anonAIStaticPrompts = [
  "I'm new to coding, what courses should I take?",
  'What can I learn for my career?',
  'What projects can help me showcase my skills?',
  'How can I earn certificates?',
];

import { Badge, BadgeProps, Text } from '@codecademy/gamut';

export const FilterCountBadge: React.FC<BadgeProps & { count: number }> = ({
  count,
  ...badgeProps
}) => (
  <Badge {...badgeProps}>
    <Text aria-hidden>{count}</Text>
    <Text screenreader>
      {`${count} ${count !== 1 ? 'filters' : 'filter'} selected`}
    </Text>
  </Badge>
);
